import reduce from 'lodash.reduce';
import omit from 'lodash.omit';

// all keys needed to write a frgament.  Optimistic responses that don't define all these keys
// will fail, often silently or breaking queries for reaons that are hard to debug.
// Basically whatever fragment you're using in the writeFragment you need every one of those keys
// Must be a better way to do this
const requiredForType = {
  // FirstReinspectionType: ['claimId', 'riScheduledReinspectionDate', 'description'],
  // SecondReinspectionType: ['claimId', 'riScheduledReinspectionDate', 'description'],
  UserType: ['email', 'fullName', 'mobilePhone', 'admin', 'inspector'],
  InternalNoteType: ['internalNoteableType', 'internalNoteableId', 'internalNote'],
  ClaimImageType: [
    'firstReinspectionId',
    'secondReinspectionId',
    'fileFilename',
    'fileMimetype',
    'filePathname',
    'room',
    'roomOther',
  ],
  ClaimItemType: [
    'position',
    'claimId',
    'firstReinspectionId',
    'secondReinspectionId',
    'oacCategoryId',
    'oacItemId',
    'oacItemPricingId',
    'itemFloor',
    'itemRoom',
    'itemTrade',
    'itemQuantity',
    'itemCostType',
    'internalItemQuantity',
    'internalMinimumChargeQuantity',
    'itemDescription',
    'itemNotes',
    'itemUnitOfMeasure',
    'itemCategory',
    'itemValidationNotes',
    'itemMinimumChargeNotes',
    'itemCustom',
    'minimumChargeUnitOfMeasure',
    'minimumChargeQuantity',
    'itemPricingDateFrom',
    'itemPricingDateTo',
    'itemPricingGroup',
    'itemPricingFixed',
    'itemPricingUnitPrice',
    'itemPricingMaterials',
    'itemPricingLabour',
    'internalItemPricingUnitPrice',
    'internalItemPricingMaterials',
    'internalItemPricingLabour',
  ],
  //   BuilderReportType: [
  //     'claimId',
  //     'assessmentCompletedByName',
  //     'assessmentCompletedByPhone',
  //     'assessmentCompletedByEmail',
  //     'propertyInformationAge',
  //     'propertyInformationYearPurchased',
  //     'propertyInformationTypeOfProperty',
  //     'propertyInformationTypeOfPropertyOther',
  //     'propertyInformationPropertyUsage',
  //     'propertyInformationPropertyUsageOther',
  //     'propertyInformationNumberOfStoreys',
  //     'propertyInformationNumberOfStoreysOther',
  //     'propertyInformationFoundation',
  //     'propertyInformationFoundationOther',
  //     'propertyInformationExteriorCladding',
  //     'propertyInformationExteriorCladdingOther',
  //     'propertyInformationWindowJoinery',
  //     'propertyInformationWindowJoineryOther',
  //     'propertyInformationRoofStyle',
  //     'propertyInformationRoofStyleOther',
  //     'propertyInformationRoofMaterial',
  //     'propertyInformationRoofMaterialOther',
  //     'propertyInformationOverallCondition',
  //     'propertyInformationAccessIntoProperty',
  //     'damageAssessmentClaimType',
  //     'damageAssessmentHighLevelReinstatementEstimate',
  //     'damageAssessmentCircumstancesAndLikelyCause',
  //     'damageAssessmentCircumstancesAndLikelyCauseVerification',
  //     'damageAssessmentResultantDamage',
  //     'damageAssessmentEvidenceOfPreexistingDamage',
  //     'damageAssessmentEvidenceOfPreexistingDamageExplanation',
  //     'damageAssessmentAlternativeAccomodationRecommended',
  //     'damageAssessmentAlternativeAccomodationRecommendedReason',
  //     'damageAssessmentAlternativeAccomodationRecommendedNights',
  //     'damageAssessmentAgeOfFlooringCoverings',
  //     'waterDamage',
  //     'waterDamageWaterType',
  //     'waterDamageWaterSource',
  //     'waterDamageWaterOriginOther',
  //     'waterDamageWaterOriginExternal',
  //     'waterDamageWaterOriginExternalOther',
  //     'waterDamageWaterOriginInternal',
  //     'waterDamageWaterOriginInternalOther',
  //     'waterDamageLengthOfLeak',
  //     'waterDamageWorkingInVicinityOfLeak',
  //     'waterDamageWorkingInVicinityOfLeakExplanation',
  //     'waterDamageDuxQuestPipingFailure',
  //     'waterDamageDuxQuestPipingFailureExplanation',
  //     'waterDamageBraidedPipingFailure',
  //     'waterDamageBraidedPipingFailureExplanation',
  //     'gradualDamage',
  //     'gradualDamageRotDecay',
  //     'gradualDamageMouldMildew',
  //     'gradualDamageCorrosionRustOxidation',
  //     'gradualDamageWeatheringFading',
  //     'gradualDamageLossOfStructuralIntegrity',
  //     'gradualDamageStainingTideMarks',
  //     'gradualDamageOtherEvidence',
  //     'gradualDamageEvidenceFor',
  //     'gradualDamageOccuringLength',
  //     'gradualDamageCause',
  //     'gradualDamageFirstAware',
  //     'gradualDamagePreventiveSteps',
  //     'gradualDamageShouldEarlierAware',
  //     'gradualDamageUnnecessaryDelay',
  //     'gradualDamageUnnecessaryExpense',
  //     'tenancy',
  //     'tenancyTenantName',
  //     'tenancyTenantPhone',
  //     'tenancyTenantEmail',
  //     'tenancyTenantFirstAware',
  //     'tenancyTenantInformedLandlordOrManager',
  //     'tenancyTenantInformedLandlordOrManagerWhen',
  //     'potentialRecovery',
  //     'potentialRecoveryName',
  //     'potentialRecoveryPhone',
  //     'potentialRecoveryEmail',
  //     'potentialRecoveryAddress',
  //     'potentialRecoveryReason',
  //     'potentialNonRecoveryReason',
  //     'potentialRecoveryAgeOfDamagedArea',
  //     'plumberOrRoofer',
  //     'plumberOrRooferName',
  //     'plumberOrRooferPhone',
  //     'plumberOrRooferEmail',
  //     'plumberOrRooferInvoiceAndReportRequested',
  //     'plumberOrRooferSourceOfLeakRepaired',
  //     'plumberOrRooferOtherInformation',
  //     'hazardsAsbestos',
  //     'hazardsAsbestosDescription',
  //     'hazardsOther',
  //     'hazardsOtherDescription',
  //     'additionalInformationComments',
  //     'additionalInformationBuilderStory',
  //   ],
};

const optimistic = (operationName, mutationData, extraData = {}, omitKeys = []) => ({
  __typename: 'Mutation',
  [operationName]: {
    __typename: mutationData.context.recordType,
    ...(mutationData.context.mutationType === 'CREATE' &&
      reduce(
        requiredForType[mutationData.context.recordType] || [],
        (r, k) => ({ ...r, [k]: null }),
        {}
      )),
    ...(mutationData.context.recordId && { id: mutationData.context.recordId }),
    ...(mutationData.variables.input && omit(mutationData.variables.input, omitKeys)),
    ...extraData,
  },
});

export default optimistic;
