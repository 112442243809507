import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';

import { settingsSet } from '../store/settings_slice';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';

import userCreateMutation from '../mutations/user_create_mutation';
import userUpdateMutation from '../mutations/user_update_mutation';
import pageUserFormQuery from '../queries/page_user_form_query';

import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import * as updateFunctions from '../update_functions';
import { userDefaultValues } from '../defaults';
import { userFormValidator } from '../validators';
import userWhiteList from '../white_lists/user_white_list';

const UserForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const [userCreate] = useMutation(userCreateMutation);
  const [userUpdate] = useMutation(userUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageUserFormQuery, {
    variables: {
      hasUserId: !!params.id,
      userId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.user) {
      return pickValues(pageData.user, userWhiteList);
    }
    return pickValues(userDefaultValues, userWhiteList);
  }, [pageData]);

  const isSelf = () => params.id && currentUser.id === parseInt(params.id, 10);

  const onCancel = () => {
    navigate('/users');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'UserType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = userUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.userUpdate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'userUpdate',
        mutationData
      );
    } else {
      mutation = userCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.userCreate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'userCreate',
        mutationData
      );
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`User ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/users');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `User ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/users');
    }
    return undefined;
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">{params.id ? 'Edit User' : 'New User'}</h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/users">
                <Button className="me-2" variant="primary">
                  All Users
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={userFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      name="email"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Email
                    </Field>
                    <Field
                      name="fullName"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Full Name
                    </Field>
                    <Field
                      name="mobilePhone"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Mobile Phone
                    </Field>
                    <Field
                      type="checkbox"
                      name="admin"
                      component={CheckboxInputField}
                      size="lg"
                      labelWidth={4}
                      inputWidth={8}
                      disabled={!currentUser.admin || isSelf()}
                    >
                      Adminstrator
                    </Field>
                    <Field
                      type="checkbox"
                      name="inspector"
                      component={CheckboxInputField}
                      size="lg"
                      labelWidth={4}
                      inputWidth={8}
                      disabled={!currentUser.admin || isSelf()}
                    >
                      Inspector
                    </Field>
                    <fieldset className="border rounded-3 p-3">
                      <legend className="float-none w-auto px-3 fs-6">
                        {params.id ? 'Update Password' : 'Create password'}
                      </legend>
                      <Field
                        name="password"
                        type="password"
                        component={InputField}
                        labelWidth={4}
                        inputWidth={8}
                        size="lg"
                      >
                        New Password
                      </Field>
                      <Field
                        name="passwordConfirmation"
                        type="password"
                        component={InputField}
                        labelWidth={4}
                        inputWidth={8}
                        size="lg"
                      >
                        Password Confirmation
                      </Field>
                    </fieldset>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default UserForm;
