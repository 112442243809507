/* eslint-disable arrow-body-style, no-nested-ternary */
import { useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  Tabs,
  Tab,
  ButtonToolbar,
  ButtonGroup,
  Modal,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
// import { createPersistDecorator } from 'final-form-persist';
import { capitalCase } from 'change-case';
import { ArrowRight as ArrowRightIcon } from 'react-feather';

import cloneDeep from 'lodash.clonedeep';
import curry from 'lodash.curry';
import forEach from 'lodash.foreach';
import indexOf from 'lodash.indexof';
import isEmpty from 'lodash.isempty';
import keys from 'lodash.keys';

import { settingsSet } from '../store/settings_slice';
import { localStorageFinalFormPersistPrefix } from '../lib/local_storage';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';
import ConditionalField from '../components/form/rff_conditional_field';
import OnChangeField from '../components/form/rff_on_change_field';

import builderReportUpdateMutation from '../mutations/builder_report_update_mutation';
import pageClaimBuilderReportFormQuery from '../queries/page_claim_builder_report_form_query';

import createPersistDecorator from '../lib/final_form_persist';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import builderReportWhiteList from '../white_lists/builder_report_white_list';
import * as updateFunctions from '../update_functions';
import { builderReportFormValidator } from '../validators';
import { builderReportDefaultValues } from '../defaults';

const ClaimBuilderReportForm = () => {
  const focusInputEl = useRef(null);
  const [key, setKey] = useState('property');
  const [modalValues, setModalValues] = useState({});
  const [modalErrors, setModalErrors] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [focusModalShow, setFocusModalShow] = useState(false);
  const [focusModalField, setFocusModalField] = useState({
    name: '',
    value: '',
    form: null,
  });

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);

  const [builderReportUpdate] = useMutation(builderReportUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageClaimBuilderReportFormQuery, {
    variables: { claimId: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.claim?.builderReport) {
      return pickValues(pageData.claim.builderReport, builderReportWhiteList);
    }
    return pickValues(builderReportDefaultValues, builderReportWhiteList);
  }, [pageData]);

  const { persistDecorator, clear: clearPersist } = useMemo(
    () =>
      createPersistDecorator({
        name: `${localStorageFinalFormPersistPrefix}:builderReportForm:${params.id}`,
        debounceTime: 500,
      }),
    [params.id]
  );

  const hideFocusModal = () => {
    setFocusModalField({ name: '', value: '', form: null });
    setFocusModalShow(false);
  };

  const saveFocusModal = () => {
    focusModalField.form.change(focusModalField.name, focusInputEl.current.value);
    hideFocusModal();
  };

  const onFocusModal = curry((form, name, onFocus, e) => {
    e.preventDefault();
    window.document.activeElement.blur();
    const fieldState = form.getFieldState(name);
    setFocusModalField({ name, value: fieldState.value || '', form });
    setFocusModalShow(true);
  });

  const onNext = (e) => {
    setKey(e.currentTarget.getAttribute('data-next'));
  };

  const onCancel = () => {
    navigate(`/claims/${params.id}`);
  };

  const onFormSubmit = async (data) => {
    const submitData = cloneDeep(data);
    // set null hidden fields, mostly 'other'
    if (submitData.waterDamageWaterSource === 'Internal Leak') {
      submitData.waterDamageWaterOriginOther = null;
      submitData.waterDamageWaterOriginExternal = null;
      submitData.waterDamageWaterOriginExternalOther = null;
    } else if (submitData.waterDamageWaterSource === 'External Leak') {
      submitData.waterDamageWaterOriginOther = null;
      submitData.waterDamageWaterOriginInternal = null;
      submitData.waterDamageWaterOriginInternalOther = null;
    } else if (submitData.waterDamageWaterSource === 'Other') {
      submitData.waterDamageWaterOriginExternal = null;
      submitData.waterDamageWaterOriginInternal = null;
      submitData.waterDamageWaterOriginExternalOther = null;
      submitData.waterDamageWaterOriginInternalOther = null;
    }
    if (
      submitData.waterDamageWaterOriginInternalOther &&
      submitData.waterDamageWaterOriginInternal !== 'Other'
    ) {
      submitData.waterDamageWaterOriginInternalOther = null;
    }
    if (
      submitData.waterDamageWaterOriginExternalOther &&
      submitData.waterDamageWaterOriginExternal !== 'Other'
    ) {
      submitData.waterDamageWaterOriginExternalOther = null;
    }
    if (
      submitData.propertyInformationTypeOfPropertyOther &&
      submitData.propertyInformationTypeOfProperty !== 'Other'
    ) {
      submitData.propertyInformationTypeOfPropertyOther = null;
    }
    if (
      submitData.propertyInformationPropertyUsageOther &&
      submitData.propertyInformationPropertyUsage !== 'Other'
    ) {
      submitData.propertyInformationPropertyUsageOther = null;
    }
    if (
      submitData.propertyInformationNumberOfStoreysOther &&
      submitData.propertyInformationNumberOfStoreys !== 'Other'
    ) {
      submitData.propertyInformationNumberOfStoreysOther = null;
    }
    if (
      submitData.propertyInformationFoundationOther &&
      submitData.propertyInformationFoundation !== 'Other'
    ) {
      submitData.propertyInformationFoundationOther = null;
    }
    if (
      submitData.propertyInformationExteriorCladdingOther &&
      submitData.propertyInformationExteriorCladding !== 'Other'
    ) {
      submitData.propertyInformationExteriorCladdingOther = null;
    }
    if (
      submitData.propertyInformationWindowJoineryOther &&
      submitData.propertyInformationWindowJoinery !== 'Other'
    ) {
      submitData.propertyInformationWindowJoineryOther = null;
    }
    if (
      submitData.propertyInformationRoofStyleOther &&
      submitData.propertyInformationRoofStyle !== 'Other'
    ) {
      submitData.propertyInformationRoofStyleOther = null;
    }
    if (
      submitData.propertyInformationRoofMaterialOther &&
      submitData.propertyInformationRoofMaterial !== 'Other'
    ) {
      submitData.propertyInformationRoofMaterialOther = null;
    }
    submitData.claimId = params.id;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { id: pageData.claim.builderReport.id, input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        mutationType: 'UPDATE',
        recordType: 'BuilderReportType',
        recordId: pageData.claim.builderReport.id,
      },
      update: updateFunctions.builderReportUpdateUpdateFunction,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'builderReportUpdate',
      mutationData
    );
    const mutation = builderReportUpdate;
    const mutationMessageAction = 'update';
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        clearPersist();
        toastSuccess(`Builder Report ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate(`/claims/${params.id}`);
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      clearPersist();
      toastWarning(
        `Builder Report ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate(`/claims/${params.id}`);
    }
    return undefined;
  };

  const onFormSubmitWithInvalid = () => {
    onFormSubmit(modalValues);
  };

  const onDamageAssessmentClaimTypeChange = (form, values) => {
    if (
      values.damageAssessmentClaimType === 'Flood' ||
      values.damageAssessmentClaimType === 'Storm'
    ) {
      form.batch(() => {
        form.change('waterDamage', true);
      });
    } else if (values.damageAssessmentClaimType === 'Gradual Damage') {
      form.batch(() => {
        form.change('gradualDamage', true);
        form.change('waterDamage', true);
      });
    }
  };

  const onDamageAssessmentEvidenceOfPreexistingDamageChange = (form, values) => {
    if (
      !values.damageAssessmentEvidenceOfPreexistingDamage &&
      values.damageAssessmentEvidenceOfPreexistingDamageExplanation
    ) {
      form.change('damageAssessmentEvidenceOfPreexistingDamageExplanation', null);
    }
  };

  const onDamageAssessmentAlternativeAccomodationRecommendedChange = (form, values) => {
    if (
      !values.damageAssessmentAlternativeAccomodationRecommended &&
      (values.damageAssessmentAlternativeAccomodationRecommendedReason ||
        values.damageAssessmentAlternativeAccomodationRecommendedNights)
    ) {
      form.batch(() => {
        form.change('damageAssessmentAlternativeAccomodationRecommendedReason', null);
        form.change('damageAssessmentAlternativeAccomodationRecommendedNights', null);
      });
    }
  };

  const onWaterDamageWorkingInVicinityOfLeakChange = (form, values) => {
    if (
      !values.waterDamageWorkingInVicinityOfLeak &&
      values.waterDamageWorkingInVicinityOfLeakExplanation
    ) {
      form.change('waterDamageWorkingInVicinityOfLeakExplanation', null);
    }
  };

  const onWaterDamageDuxQuestPipingFailureChange = (form, values) => {
    if (
      !values.waterDamageDuxQuestPipingFailure &&
      values.waterDamageDuxQuestPipingFailureExplanation
    ) {
      form.change('waterDamageDuxQuestPipingFailureExplanation', null);
    }
  };

  const onWaterDamageBraidedPipingFailureChange = (form, values) => {
    if (
      !values.waterDamageBraidedPipingFailure &&
      values.waterDamageBraidedPipingFailureExplanation
    ) {
      form.change('waterDamageBraidedPipingFailureExplanation', null);
    }
  };

  const onTenancyTenantInformedLandlordOrManagerChange = (form, values) => {
    if (
      !values.tenancyTenantInformedLandlordOrManager &&
      values.tenancyTenantInformedLandlordOrManagerWhen
    ) {
      form.change('tenancyTenantInformedLandlordOrManagerWhen', null);
    }
  };

  const onHazardsOtherChange = (form, values) => {
    if (!values.hazardsOther && values.hazardsOtherDescription) {
      form.change('hazardsOtherDescription', null);
    }
  };

  const showErrorModal = (values, errors, form) => {
    const errorKeys = keys(errors);
    forEach(errorKeys, (errorKey) => {
      form.mutators.setFieldTouched(errorKey, true);
    });
    const formattedErrors = errorKeys.map((k) => capitalCase(k));
    setModalValues(values);
    setModalErrors(formattedErrors);
    setModalShow(true);
  };

  const hideErrorModal = () => {
    setModalValues({});
    setModalErrors([]);
    setModalShow(false);
  };

  const enterFocusModal = () => {
    const { value } = focusInputEl.current;
    focusInputEl.current.value = null;
    focusInputEl.current.value = value;
    focusInputEl.current.focus();
  };

  const renderSectionButtons = (
    values,
    pristine,
    submitting,
    errors,
    form,
    handleSubmit
  ) => {
    const tabs = [
      'overview',
      'property',
      'damage',
      'waterDamage',
      'gradualDamage',
      'tenancy',
      'potentialRecovery',
      'plumberOrRoofer',
      'hazards',
      'additional',
    ];
    const candidates = tabs;
    // .map((t) => (values[t] === undefined ? t : values[t] && t))
    // .filter((t) => t);

    const currentIndex = indexOf(candidates, key);
    const prev = currentIndex > 0 ? candidates[currentIndex - 1] : null;
    const next = currentIndex < candidates.length ? candidates[currentIndex + 1] : null;

    return (
      <Row>
        <Col sm={12}>
          <ButtonToolbar style={{ justifyContent: 'space-between' }}>
            <ButtonGroup>
              {isEmpty(errors) ? (
                <Button
                  variant="primary"
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                >
                  {submitting && <SubmitButtonSpinner />}
                  Save
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => showErrorModal(values, errors, form)}
                >
                  Save
                </Button>
              )}
            </ButtonGroup>
            <ButtonGroup>
              <Button
                variant="primary"
                onClick={onNext}
                data-next={prev}
                disabled={!prev || submitting}
              >
                Prev
              </Button>
              <Button variant="danger" onClick={onCancel} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onNext}
                data-next={next}
                disabled={!next || submitting}
              >
                Next
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
    );
  };

  const renderContent = () => {
    const {
      enums: { enums },
    } = pageData;
    return (
      <>
        <Modal
          show={focusModalShow}
          onHide={hideFocusModal}
          size="xl"
          onEntered={enterFocusModal}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>{`Editing ${capitalCase(focusModalField.name)}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <textarea
                ref={focusInputEl}
                type="text"
                defaultValue={focusModalField.value}
                style={{
                  fontSize: 'large',
                  width: '100%',
                  height: `calc(${window.innerHeight}px - 208px)`,
                  overflowX: 'scroll',
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="danger" onClick={saveFocusModal}>
              Save
            </Button>
            <Button type="button" variant="primary" onClick={hideFocusModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalShow} onHide={hideErrorModal} centered>
          <Modal.Header>
            <Modal.Title>
              {`Fix ${modalErrors.length} Error${modalErrors.length > 1 ? 's' : ''}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              The following fields have missing or incorrect information. Check all tabs.
              <br />
              You will not be able to submit the claim until all errors are resolved
            </p>
            {modalErrors.map((modalError) => (
              <p key={modalError}>
                <ArrowRightIcon className="feather-sm me-3" />
                {modalError}
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="danger"
              onClick={() => onFormSubmitWithInvalid()}
            >
              Save Anyway
            </Button>
            <Button type="button" variant="primary" onClick={hideErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">Edit Builder&apos;s Report</h1>
              </div>
              <div className="float-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => pageRefetch()}
                    disabled={!settingsOnline}
                  >
                    Refresh
                  </Button>
                  <LinkContainer to={`/claims/${params.id}`}>
                    <Button variant="primary">Job Sheet</Button>
                  </LinkContainer>
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FinalForm
              initialValues={initialValues}
              onSubmit={(data) => onFormSubmit(data)}
              validate={builderReportFormValidator}
              mutators={{ setFieldTouched }}
              decorators={[persistDecorator]}
            >
              {({ handleSubmit, pristine, errors, form, submitting, values }) => (
                <form noValidate>
                  <OnChangeField name="damageAssessmentEvidenceOfPreexistingDamage">
                    {() =>
                      onDamageAssessmentEvidenceOfPreexistingDamageChange(form, values)
                    }
                  </OnChangeField>
                  <OnChangeField name="damageAssessmentAlternativeAccomodationRecommended">
                    {() =>
                      onDamageAssessmentAlternativeAccomodationRecommendedChange(
                        form,
                        values
                      )
                    }
                  </OnChangeField>
                  <OnChangeField name="waterDamageWorkingInVicinityOfLeak">
                    {() => onWaterDamageWorkingInVicinityOfLeakChange(form, values)}
                  </OnChangeField>
                  <OnChangeField name="waterDamageDuxQuestPipingFailure">
                    {() => onWaterDamageDuxQuestPipingFailureChange(form, values)}
                  </OnChangeField>
                  <OnChangeField name="waterDamageBraidedPipingFailure">
                    {() => onWaterDamageBraidedPipingFailureChange(form, values)}
                  </OnChangeField>
                  <OnChangeField name="damageAssessmentClaimType">
                    {() => onDamageAssessmentClaimTypeChange(form, values)}
                  </OnChangeField>
                  <OnChangeField name="tenancyTenantInformedLandlordOrManager">
                    {() => onTenancyTenantInformedLandlordOrManagerChange(form, values)}
                  </OnChangeField>
                  <OnChangeField name="hazardsOther">
                    {() => onHazardsOtherChange(form, values)}
                  </OnChangeField>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    <Tab eventKey="overview" title="Assessor">
                      <Card>
                        <Card.Body>
                          <Field
                            name="assessmentCompletedByName"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Assessor Name
                          </Field>
                          <Field
                            name="assessmentCompletedByEmail"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Assessor Email
                          </Field>
                          <Field
                            name="assessmentCompletedByPhone"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Assessor Phone
                          </Field>
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="property" title="Property Information">
                      <Card>
                        <Card.Body>
                          <Field
                            name="propertyInformationAge"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Property Age (yrs)
                          </Field>
                          <Field
                            name="propertyInformationYearPurchased"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Year Purchased (yyyy)
                          </Field>
                          <Field
                            name="propertyInformationTypeOfProperty"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationTypeOfProperty.map(
                              ({ name, description }) => ({
                                id: name,
                                name: description ? `${name} (${description})` : name,
                              })
                            )}
                            defaultSelectOptionName="select property type..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Type of Property
                          </Field>
                          <ConditionalField
                            when="propertyInformationTypeOfProperty"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationTypeOfPropertyOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Type of Property Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationPropertyUsage"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationPropertyUsage.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select property usage..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Property Usage
                          </Field>
                          <ConditionalField
                            when="propertyInformationPropertyUsage"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationPropertyUsageOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Property Usage Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationNumberOfStoreys"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationNumberOfStoreys.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select number of storeys..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Number of Storeys
                          </Field>
                          <ConditionalField
                            when="propertyInformationNumberOfStoreys"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationNumberOfStoreysOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Number of Storeys Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationFoundation"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationFoundation.map(
                              ({ name, description }) => ({
                                id: name,
                                name: description ? `${name} (${description})` : name,
                              })
                            )}
                            defaultSelectOptionName="select foundation..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Foundation
                          </Field>
                          <ConditionalField
                            when="propertyInformationFoundation"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationFoundationOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Foundation Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationExteriorCladding"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationExteriorCladding.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select cladding..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Cladding
                          </Field>
                          <ConditionalField
                            when="propertyInformationExteriorCladding"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationExteriorCladdingOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Cladding Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationWindowJoinery"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationWindowJoinery.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select window joinery..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Window Joinery
                          </Field>
                          <ConditionalField
                            when="propertyInformationWindowJoinery"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationWindowJoineryOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Window Joinery Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationRoofStyle"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationRoofStyle.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select roof style..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Roof Style
                          </Field>
                          <ConditionalField
                            when="propertyInformationRoofStyle"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationRoofStyleOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Roof Style Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationRoofMaterial"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationRoofMaterial.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select roof material..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Roof Material
                          </Field>
                          <ConditionalField
                            when="propertyInformationRoofMaterial"
                            is="Other"
                          >
                            <Field
                              name="propertyInformationRoofMaterialOther"
                              component={InputField}
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            >
                              Roof Material Other
                            </Field>
                          </ConditionalField>
                          <Field
                            name="propertyInformationOverallCondition"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationOverallCondition.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select overall condition..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Overall Condition
                          </Field>
                          <Field
                            name="propertyInformationAccessIntoProperty"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.PropertyInformationAccessIntoProperty.map(
                              (i) => ({
                                id: i,
                                name: i,
                              })
                            )}
                            defaultSelectOptionName="select property access..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Property Access
                          </Field>
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="damage" title="Damage Assessment">
                      <Card>
                        <Card.Body>
                          <Row>
                            <Col>
                              <Field
                                name="damageAssessmentClaimType"
                                component={InputField}
                                asElement="select"
                                selectOptions={enums.DamageAssessmentClaimType.map(
                                  (i) => ({
                                    id: i,
                                    name: i,
                                  })
                                )}
                                defaultSelectOptionName="select claim type..."
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Claim Type
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Field
                                name="damageAssessmentHighLevelReinstatementEstimate"
                                component={InputField}
                                asElement="select"
                                // eslint-disable-next-line max-len
                                selectOptions={enums.DamageAssessmentHighLevelReinstatementEstimate.map(
                                  (i) => ({
                                    id: i,
                                    name: i,
                                  })
                                )}
                                defaultSelectOptionName="select high level estimate..."
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                High Level Estimate
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Field
                                name="damageAssessmentCircumstancesAndLikelyCause"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                                modalOnFocus
                              >
                                Circumstances and Likely Cause
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Field
                                name="damageAssessmentCircumstancesAndLikelyCauseVerification"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                              >
                                Likely Cause Verification
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Field
                                name="damageAssessmentResultantDamage"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                              >
                                Resultant Damage
                              </Field>
                            </Col>
                          </Row>
                          <Field
                            type="checkbox"
                            name="damageAssessmentEvidenceOfPreexistingDamage"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Evidence of Pre-existing Damage
                          </Field>
                          <ConditionalField
                            when="damageAssessmentEvidenceOfPreexistingDamage"
                            is
                          >
                            <Field
                              name="damageAssessmentEvidenceOfPreexistingDamageExplanation"
                              component={InputField}
                              placeholder="enter pre-existing damage explanation"
                              asElement="textarea"
                              size="lg"
                              rows={2}
                              customOnFocus={onFocusModal(form)}
                              labelWidth={4}
                              inputWidth={8}
                            />
                          </ConditionalField>
                          <Field
                            type="checkbox"
                            name="damageAssessmentAlternativeAccomodationRecommended"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Alternative Accomodation Recommended
                          </Field>
                          <ConditionalField
                            when="damageAssessmentAlternativeAccomodationRecommended"
                            is
                          >
                            <Field
                              name="damageAssessmentAlternativeAccomodationRecommendedReason"
                              component={InputField}
                              placeholder="enter reason for alternative accomodation recommendation"
                              asElement="textarea"
                              size="lg"
                              rows={2}
                              customOnFocus={onFocusModal(form)}
                              labelWidth={4}
                              inputWidth={8}
                            />
                          </ConditionalField>
                          <ConditionalField
                            when="damageAssessmentAlternativeAccomodationRecommended"
                            is
                          >
                            <Field
                              name="damageAssessmentAlternativeAccomodationRecommendedNights"
                              component={InputField}
                              placeholder="enter nights required for alternative accomodation"
                              labelWidth={4}
                              inputWidth={8}
                              size="lg"
                            />
                          </ConditionalField>
                          <Row>
                            <Col>
                              <Field
                                name="damageAssessmentAgeOfFlooringCoverings"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Age of Floor Coverings
                              </Field>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="waterDamage" title="Water Damage">
                      <Card>
                        <Card.Body>
                          <Field
                            type="checkbox"
                            name="waterDamage"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Water Damage
                          </Field>
                          {values.waterDamage && (
                            <>
                              <Field
                                name="waterDamageWaterType"
                                component={InputField}
                                asElement="select"
                                selectOptions={enums.WaterDamageWaterType.map((i) => ({
                                  id: i,
                                  name: i,
                                }))}
                                defaultSelectOptionName="select water type..."
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Water Type
                              </Field>
                              <Field
                                name="waterDamageWaterSource"
                                component={InputField}
                                asElement="select"
                                selectOptions={enums.WaterDamageWaterSource.map((i) => ({
                                  id: i,
                                  name: i,
                                }))}
                                defaultSelectOptionName="select water source..."
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Water Origin
                              </Field>
                              <ConditionalField when="waterDamageWaterSource" is="Other">
                                <Field
                                  name="waterDamageWaterOriginOther"
                                  component={InputField}
                                  labelWidth={4}
                                  inputWidth={8}
                                  size="lg"
                                >
                                  Water Origin - Other
                                </Field>
                              </ConditionalField>
                              <ConditionalField
                                when="waterDamageWaterSource"
                                is="External Leak"
                              >
                                <Field
                                  name="waterDamageWaterOriginExternal"
                                  component={InputField}
                                  asElement="select"
                                  selectOptions={enums.WaterDamageWaterOriginExternal.map(
                                    (i) => ({
                                      id: i,
                                      name: i,
                                    })
                                  )}
                                  defaultSelectOptionName="select external source..."
                                  labelWidth={4}
                                  inputWidth={8}
                                  size="lg"
                                  groupClassName="mb-2"
                                >
                                  Water Origin - External
                                </Field>
                                <ConditionalField
                                  when="waterDamageWaterOriginExternal"
                                  is="Other"
                                >
                                  <Field
                                    name="waterDamageWaterOriginExternalOther"
                                    component={InputField}
                                    labelWidth={4}
                                    inputWidth={8}
                                    size="lg"
                                  >
                                    Water Origin - External - Other
                                  </Field>
                                </ConditionalField>
                              </ConditionalField>
                              <ConditionalField
                                when="waterDamageWaterSource"
                                is="Internal Leak"
                              >
                                <Field
                                  name="waterDamageWaterOriginInternal"
                                  component={InputField}
                                  asElement="select"
                                  selectOptions={enums.WaterDamageWaterOriginInternal.map(
                                    (i) => ({
                                      id: i,
                                      name: i,
                                    })
                                  )}
                                  defaultSelectOptionName="select internal source..."
                                  labelWidth={4}
                                  inputWidth={8}
                                  size="lg"
                                  groupClassName="mb-2"
                                >
                                  Water Origin - Internal
                                </Field>
                                <ConditionalField
                                  when="waterDamageWaterOriginInternal"
                                  is="Other"
                                >
                                  <Field
                                    name="waterDamageWaterOriginInternalOther"
                                    component={InputField}
                                    labelWidth={4}
                                    inputWidth={8}
                                    size="lg"
                                  >
                                    Water Origin - Internal - Other
                                  </Field>
                                </ConditionalField>
                              </ConditionalField>
                              <Field
                                name="waterDamageLengthOfLeak"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Length of Leak
                              </Field>
                              <Field
                                type="checkbox"
                                name="waterDamageWorkingInVicinityOfLeak"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Private Work in Vicinity of Leak?
                              </Field>
                              <ConditionalField
                                when="waterDamageWorkingInVicinityOfLeak"
                                is
                              >
                                <Field
                                  name="waterDamageWorkingInVicinityOfLeakExplanation"
                                  component={InputField}
                                  placeholder="enter private work in vicinity of leak explanation"
                                  asElement="textarea"
                                  size="lg"
                                  rows={2}
                                  customOnFocus={onFocusModal(form)}
                                  labelWidth={4}
                                  inputWidth={8}
                                />
                              </ConditionalField>
                              <Field
                                type="checkbox"
                                name="waterDamageDuxQuestPipingFailure"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Dux Quest Piping Failure?
                              </Field>
                              <ConditionalField
                                when="waterDamageDuxQuestPipingFailure"
                                is
                              >
                                <Field
                                  name="waterDamageDuxQuestPipingFailureExplanation"
                                  component={InputField}
                                  placeholder="enter dux quest piping failure explanation"
                                  asElement="textarea"
                                  size="lg"
                                  rows={2}
                                  customOnFocus={onFocusModal(form)}
                                  labelWidth={4}
                                  inputWidth={8}
                                />
                              </ConditionalField>
                              <Field
                                type="checkbox"
                                name="waterDamageBraidedPipingFailure"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Braided Piping Failure?
                              </Field>
                              <ConditionalField when="waterDamageBraidedPipingFailure" is>
                                <Field
                                  name="waterDamageBraidedPipingFailureExplanation"
                                  component={InputField}
                                  placeholder="enter braided piping failure explanation"
                                  asElement="textarea"
                                  size="lg"
                                  rows={2}
                                  customOnFocus={onFocusModal(form)}
                                  labelWidth={4}
                                  inputWidth={8}
                                />
                              </ConditionalField>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="gradualDamage" title="Gradual Damage">
                      <Card>
                        <Card.Body>
                          <Field
                            type="checkbox"
                            name="gradualDamage"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Gradual Damage
                          </Field>
                          {values.gradualDamage && (
                            <>
                              <Field
                                type="checkbox"
                                name="gradualDamageRotDecay"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Rot / Decay
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageMouldMildew"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Mould / Mildew
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageCorrosionRustOxidation"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Corrosion / Rust / Oxidation
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageWeatheringFading"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Weathering / Fading
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageLossOfStructuralIntegrity"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Loss of Structural Integrity
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageStainingTideMarks"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Staining / Tidemarks
                              </Field>
                              <Field
                                name="gradualDamageOtherEvidence"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                              >
                                Other Gradual Damage
                              </Field>
                              <Field
                                name="gradualDamageEvidenceFor"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                              >
                                What Evidence Suggests Damage is Gradual?
                              </Field>
                              <Field
                                name="gradualDamageOccuringLength"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Opinion as to Length of Occurance
                              </Field>
                              <Field
                                name="gradualDamageCause"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                              >
                                Cause of the Damage?
                              </Field>
                              <Field
                                name="gradualDamageFirstAware"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Insured First Aware
                              </Field>
                              <Field
                                name="gradualDamagePreventiveSteps"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                rows={2}
                                size="lg"
                                customOnFocus={onFocusModal(form)}
                              >
                                Preventive and Minimisation Steps Taken
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageShouldEarlierAware"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Should Have Been Aware Earlier?
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageUnnecessaryDelay"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Unnecessary Delay in Rectification
                              </Field>
                              <Field
                                type="checkbox"
                                name="gradualDamageUnnecessaryExpense"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Delay Caused Unnecessary Expense
                              </Field>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="tenancy" title="Tenancy">
                      <Card>
                        <Card.Body>
                          <Field
                            type="checkbox"
                            name="tenancy"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Tenanted
                          </Field>
                          {values.tenancy && (
                            <>
                              <Field
                                name="tenancyTenantName"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Tenant Name
                              </Field>
                              <Field
                                name="tenancyTenantPhone"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Tenant Phone
                              </Field>
                              <Field
                                name="tenancyTenantEmail"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Tenant Email
                              </Field>
                              <Field
                                name="tenancyTenantFirstAware"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Tenant First Aware
                              </Field>
                              <Field
                                type="checkbox"
                                name="tenancyTenantInformedLandlordOrManager"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Has Informed Landlord or Manager
                              </Field>
                              <ConditionalField
                                when="tenancyTenantInformedLandlordOrManager"
                                is
                              >
                                <Field
                                  name="tenancyTenantInformedLandlordOrManagerWhen"
                                  component={InputField}
                                  placeholder="enter informed manager or landlord date"
                                  size="lg"
                                  labelWidth={4}
                                  inputWidth={8}
                                />
                              </ConditionalField>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="potentialRecovery" title="Potential Recovery">
                      <Card>
                        <Card.Body>
                          <Field
                            type="checkbox"
                            name="potentialRecovery"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Potential Recovery
                          </Field>
                          {values.potentialRecovery && (
                            <>
                              <Field
                                name="potentialRecoveryName"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Potential Recovery Company or Name
                              </Field>
                              <Field
                                name="potentialRecoveryPhone"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Phone
                              </Field>
                              <Field
                                name="potentialRecoveryEmail"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Email
                              </Field>
                              <Field
                                name="potentialRecoveryAddress"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Address
                              </Field>
                              <Field
                                name="potentialRecoveryReason"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                size="lg"
                                rows={2}
                                customOnFocus={onFocusModal(form)}
                              >
                                Potential Recovery Reason
                              </Field>
                              <Field
                                name="potentialNonRecoveryReason"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                size="lg"
                                rows={2}
                                customOnFocus={onFocusModal(form)}
                              >
                                Potential Non-Recovery Reason
                              </Field>
                              <Field
                                name="potentialRecoveryAgeOfDamagedArea"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Estimated Age of Damaged Building Area
                              </Field>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="plumberOrRoofer" title="Plumber or Roofer">
                      <Card>
                        <Card.Body>
                          <Field
                            type="checkbox"
                            name="plumberOrRoofer"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Plumber or Roofer
                          </Field>
                          {values.plumberOrRoofer && (
                            <>
                              <Field
                                name="plumberOrRooferName"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Plumber / Roofer Company or Name
                              </Field>
                              <Field
                                name="plumberOrRooferPhone"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Phone
                              </Field>
                              <Field
                                name="plumberOrRooferEmail"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                size="lg"
                              >
                                Email
                              </Field>
                              <Field
                                type="checkbox"
                                name="plumberOrRooferInvoiceAndReportRequested"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Invoice and Report Requested?
                              </Field>
                              <Field
                                type="checkbox"
                                name="plumberOrRooferSourceOfLeakRepaired"
                                component={CheckboxInputField}
                                size="lg"
                                labelWidth={4}
                                inputWidth={8}
                              >
                                Source of Leak Repaired?
                              </Field>
                              <Field
                                name="plumberOrRooferOtherInformation"
                                component={InputField}
                                labelWidth={4}
                                inputWidth={8}
                                asElement="textarea"
                                size="lg"
                                rows={2}
                                customOnFocus={onFocusModal(form)}
                              >
                                Other Plumber / Roofer Information
                              </Field>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="hazards" title="Hazards">
                      <Card>
                        <Card.Body>
                          <Field
                            name="hazardsAsbestos"
                            component={InputField}
                            asElement="select"
                            selectOptions={enums.HazardsAsbestos.map((i) => ({
                              id: i,
                              name: i,
                            }))}
                            defaultSelectOptionName="select asbestos status..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Is There Any Asbestos
                          </Field>
                          <Field
                            name="hazardsAsbestosDescription"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            asElement="textarea"
                            size="lg"
                            rows={2}
                            customOnFocus={onFocusModal(form)}
                          >
                            Asbestos Comments
                          </Field>
                          <Field
                            type="checkbox"
                            name="hazardsOther"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Other Hazards?
                          </Field>
                          <ConditionalField when="hazardsOther" is>
                            <Field
                              name="hazardsOtherDescription"
                              component={InputField}
                              placeholder="enter other hazards description"
                              asElement="textarea"
                              size="lg"
                              labelWidth={4}
                              inputWidth={8}
                              rows={2}
                              customOnFocus={onFocusModal(form)}
                            />
                          </ConditionalField>
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab eventKey="additional" title="Additional Information">
                      <Card>
                        <Card.Body>
                          <Field
                            name="additionalInformationComments"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            asElement="textarea"
                            size="lg"
                            rows={2}
                            customOnFocus={onFocusModal(form)}
                          >
                            Additional Information
                          </Field>
                          <Field
                            name="additionalInformationBuilderStory"
                            placeholder="Add the date and a quick description of the damage in your own words"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            asElement="textarea"
                            size="lg"
                            rows={2}
                            customOnFocus={onFocusModal(form)}
                          >
                            Builder&apos;s Story
                          </Field>
                        </Card.Body>
                      </Card>
                    </Tab>
                  </Tabs>
                  <Row>
                    <Col>
                      {renderSectionButtons(
                        values,
                        pristine,
                        submitting,
                        errors,
                        form,
                        handleSubmit
                      )}
                    </Col>
                  </Row>
                </form>
              )}
            </FinalForm>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimBuilderReportForm;
