import claimQuery from '../queries/claim_query';
import cleanCache from './clean_cache';

const claimUpdateUpdateFunction = (cache, { data: { claimUpdate } }) => {
  const data = { claim: claimUpdate };
  cache.writeQuery({ query: claimQuery, variables: { id: claimUpdate.id.toString() }, data });
  cleanCache(cache);
};

export default claimUpdateUpdateFunction;
