import userQuery from '../queries/user_query';
import cleanCache from './clean_cache';

const userListUpdateFunction = (client, userList) => {
  userList.map((user) => {
    const data = { user };
    return client.writeQuery({ query: userQuery, variables: { id: user.id.toString() }, data });
  });
  cleanCache(client.cache);
};

export default userListUpdateFunction;
