import claimQuery from '../queries/claim_query';
import cleanCache from './clean_cache';

const claimListUpdateFunction = (client, claimList) => {
  claimList.map((claim) => {
    const data = { claim };
    return client.writeQuery({ query: claimQuery, variables: { id: claim.id.toString() }, data });
  });
  cleanCache(client.cache);
};

export default claimListUpdateFunction;
