import merge from 'lodash.merge';

import claimQuery from '../queries/claim_query';
import cleanCache from './clean_cache';

const claimResetUpdateFunction = (cache, { data: { claimReset } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({
      query: claimQuery,
      variables: { id: claimReset.id.toString() },
    });
  } catch {
    currentData = { claim: {} };
  }
  const data = { claim: merge({}, currentData.claim, claimReset) };
  cache.writeQuery({
    query: claimQuery,
    variables: { id: claimReset.id.toString() },
    data,
  });
  cleanCache(cache);
};

export default claimResetUpdateFunction;
