import userListQuery from '../queries/user_list_query';
import cleanCache from './clean_cache';

const userDeleteUpdateFunction = (cache, { data: { userDelete } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({ query: userListQuery });
  } catch {
    currentData = { userList: [] };
  }
  const data = { userList: currentData.userList.filter((user) => userDelete.id !== user.id) };
  cache.writeQuery({ query: userListQuery, data });
  cache.evict({ fieldName: 'user', args: { id: userDelete.id.toString() } });
  cleanCache(cache);
};

export default userDeleteUpdateFunction;
