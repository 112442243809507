import merge from 'lodash.merge';

import claimQuery from '../queries/claim_query';
import cleanCache from './clean_cache';

const claimRiStatusUpdateFunction = (cache, { data: { claimRiStatusUpdate } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({
      query: claimQuery,
      variables: { id: claimRiStatusUpdate.id.toString() },
    });
  } catch {
    currentData = { claim: {} };
  }
  const data = { claim: merge({}, currentData.claim, claimRiStatusUpdate) };
  cache.writeQuery({
    query: claimQuery,
    variables: { id: claimRiStatusUpdate.id.toString() },
    data,
  });
  cleanCache(cache);
};

export default claimRiStatusUpdateFunction;
