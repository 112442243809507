import claimInternalNotesFragment from '../fragments/claim_internal_notes_fragment';
import cleanCache from './clean_cache';

const claimInternalNoteDeleteUpdateFunction = (
  cache,
  { data: { claimInternalNoteDelete } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      // TODO THESE NEED POLYMORPHIC-ING
      id: `ClaimType-${claimInternalNoteDelete.internalNoteableId}`,
      fragment: claimInternalNotesFragment,
      fragmentName: 'ClaimInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...claimInternalNoteDelete, err });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const internalNotes = currentData.internalNotes.filter(
        (note) => claimInternalNoteDelete.id !== note.id
      );
      currentData = cache.writeFragment({
        id: `ClaimType-${claimInternalNoteDelete.internalNoteableId}`,
        fragment: claimInternalNotesFragment,
        fragmentName: 'ClaimInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...claimInternalNoteDelete,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};

export default claimInternalNoteDeleteUpdateFunction;
