import userListQuery from '../queries/user_list_query';
import userQuery from '../queries/user_query';
import cleanCache from './clean_cache';

const userCreateUpdateFunction = (cache, { data: { userCreate } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({ query: userListQuery });
  } catch {
    currentData = { userList: [] };
  }
  cache.writeQuery({
    query: userListQuery,
    data: { userList: [...currentData.userList, userCreate] },
  });
  cache.writeQuery({
    query: userQuery,
    variables: { id: userCreate.id.toString() },
    data: { user: userCreate },
  });
  cleanCache(cache);
};

export default userCreateUpdateFunction;
