import claimClaimImagesFragment from '../fragments/claim_claim_images_fragment';
import cleanCache from './clean_cache';

const claimClaimImageDeleteUpdateFunction = (cache, { data: { claimClaimImageDelete } }) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${claimClaimImageDelete.claimId}`, // The value of the to-do item's unique identifier
      fragment: claimClaimImagesFragment,
      fragmentName: 'ClaimClaimImagesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...claimClaimImageDelete, err });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const claimImages = currentData.claimImages.filter(
        (image) => claimClaimImageDelete.id !== image.id
      );
      currentData = cache.writeFragment({
        id: `ClaimType-${claimClaimImageDelete.claimId}`, // The value of the to-do item's unique identifier
        fragment: claimClaimImagesFragment,
        fragmentName: 'ClaimClaimImagesFragment',
        data: { claimImages },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...claimClaimImageDelete,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};

export default claimClaimImageDeleteUpdateFunction;
