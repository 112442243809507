import firstReinspectionInternalNotesFragment from '../fragments/first_reinspection_internal_notes_fragment';
import cleanCache from './clean_cache';

const firstReinspectionInternalNoteDeleteUpdateFunction = (
  cache,
  { data: { firstReinspectionInternalNoteDelete } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `FirstReinspectionType-${firstReinspectionInternalNoteDelete.internalNoteableId}`,
      fragment: firstReinspectionInternalNotesFragment,
      fragmentName: 'FirstReinspectionInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', {
        ...firstReinspectionInternalNoteDelete,
        err,
      });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const internalNotes = currentData.internalNotes.filter(
        (note) => firstReinspectionInternalNoteDelete.id !== note.id
      );
      currentData = cache.writeFragment({
        id: `FirstReinspectionType-${firstReinspectionInternalNoteDelete.internalNoteableId}`,
        fragment: firstReinspectionInternalNotesFragment,
        fragmentName: 'FirstReinspectionInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...firstReinspectionInternalNoteDelete,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};

export default firstReinspectionInternalNoteDeleteUpdateFunction;
