import qccInspectionFragment from '../fragments/qcc_inspection_type_base_fragment';
import cleanCache from './clean_cache';

const qccInspectionUpdateUpdateFunction = (cache, { data: { qccInspectionUpdate } }) => {
  const data = qccInspectionUpdate;
  cache.writeFragment({
    fragment: qccInspectionFragment,
    fragmentName: 'QccInspectionTypeBase',
    id: `QccInspectionType-${qccInspectionUpdate.id}`,
    data,
  });
  cleanCache(cache);
};

export default qccInspectionUpdateUpdateFunction;
