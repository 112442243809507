/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useMemo } from 'react';
import { Col, Row, ListGroup, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
// import delay from 'lodash.delay';
import differenceWith from 'lodash.differencewith';
import first from 'lodash.first';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import map from 'lodash.map';
import pick from 'lodash.pick';
import reverse from 'lodash.reverse';
import sortBy from 'lodash.sortby';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import WorkCenterCategoryListWorkCenterItemShow from '../components/work_center_category_list/work_center_item_show';
import WorkCenterItemForm from './work_center_item_form';

import { coerceInput, handleSubmitError } from '../lib/utils';
import { toastSuccess, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import workCenterCategoryUpdateMutation from '../mutations/work_center_category_update_mutation';
import workCenterItemUpdateMutation from '../mutations/work_center_item_update_mutation';
import workCenterCategoryWorkCenterItemCreateMutation from '../mutations/work_center_category_work_center_item_create_mutation';
import workCenterCategoryWorkCenterItemUpdateMutation from '../mutations/work_center_category_work_center_item_update_mutation';
import workCenterCategoryWorkCenterItemDeleteMutation from '../mutations/work_center_category_work_center_item_delete_mutation';
import pageWorkCenterCategoryListQuery from '../queries/page_work_center_category_list_query';

// todo check the three mutations are they just updating positions?  in which case chage the mutation to be position only.
// workCenterCategoryUpdateMutation
// workCenterItemUpdateMutation
// workCenterItemPricingUpdateMutation is this even used??
// todo validation for work center item form

const WorkCenterCategoryList = () => {
  const [editModalToggle, setEditModalToggle] = useState(false);
  const [workCenterCategoryList, setWorkCenterCategoryList] = useState([]);
  const [selectedWorkCenterItem, setSelectedWorkCenterItem] = useState({});
  const [selectedWorkCenterItemId, setSelectedWorkCenterItemId] = useState(null);
  const [selectedWorkCenterCategory, setSelectedWorkCenterCategory] = useState({});
  const [selectedWorkCenterCategoryId, setSelectedWorkCenterCategoryId] = useState(null);

  const dispatch = useDispatch();

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const [workCenterItemUpdate] = useMutation(workCenterItemUpdateMutation);
  const [workCenterCategoryUpdate] = useMutation(workCenterCategoryUpdateMutation);
  const [workCenterCategoryWorkCenterItemCreate] = useMutation(
    workCenterCategoryWorkCenterItemCreateMutation
  );
  const [workCenterCategoryWorkCenterItemUpdate] = useMutation(
    workCenterCategoryWorkCenterItemUpdateMutation
  );
  const [workCenterCategoryWorkCenterItemDelete] = useMutation(
    workCenterCategoryWorkCenterItemDeleteMutation
  );

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageWorkCenterCategoryListQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const sortedWorkCenterItemPricings = () =>
    reverse(
      sortBy(clone(get(selectedWorkCenterItem, ['workCenterItemPricings'], [])), [
        'createdAt',
      ])
    );

  const workCenterCategories = useMemo(() => {
    if (pageData?.oacCategoryList) {
      return get(pageData, ['oacCategoryList']);
    }
    return [];
  }, [pageData]);

  useEffect(() => {
    if (workCenterCategories) {
      const sortedWorkCenterCategories = map(
        sortBy(pageData.workCenterCategoryList, 'position'),
        (c) => ({
          ...c,
          workCenterItems: sortBy(c.workCenterItems, 'position'),
        })
      );
      setWorkCenterCategoryList(sortedWorkCenterCategories);
    }
  }, [workCenterCategories]);

  useEffect(() => {
    if (selectedWorkCenterCategoryId) {
      try {
        const workCenterCategory = workCenterCategoryList.find(
          (ic) => ic.id === selectedWorkCenterCategoryId
        );
        setSelectedWorkCenterCategory(workCenterCategory || {});
      } catch (err) {
        setSelectedWorkCenterCategory({});
      }
    } else {
      setSelectedWorkCenterCategory({});
    }
  }, [workCenterCategoryList, selectedWorkCenterCategoryId]);

  useEffect(() => {
    if (selectedWorkCenterCategoryId && selectedWorkCenterItemId) {
      try {
        const workCenterItem = selectedWorkCenterCategory.workCenterItems.find(
          (i) => i.id === selectedWorkCenterItemId
        );
        setSelectedWorkCenterItem(workCenterItem || {});
      } catch (err) {
        setSelectedWorkCenterItem({});
      }
    } else {
      setSelectedWorkCenterItem({});
    }
  }, [
    selectedWorkCenterCategory,
    selectedWorkCenterCategoryId,
    selectedWorkCenterItemId,
  ]);

  const workCenterCategoryClicked = (e) => {
    const categoryId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    setSelectedWorkCenterItemId(null);
    if (selectedWorkCenterCategoryId === categoryId) {
      setSelectedWorkCenterCategoryId(null);
    } else {
      setSelectedWorkCenterCategoryId(categoryId);
      // delay(
      //   (target) =>
      //     // eslint-disable-next-line implicit-arrow-linebreak
      //     target.scrollIntoView({ behavior: 'smooth', block: 'start' }),
      //   50,
      //   e.currentTarget
      // );
    }
  };

  const workCenterItemClicked = (e) => {
    e.stopPropagation();
    const itemId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    if (selectedWorkCenterItemId === itemId) {
      setSelectedWorkCenterItemId(null);
    } else {
      setSelectedWorkCenterItemId(itemId);
    }
  };

  const onCategoryDragEnd = async (sourceIndex, destIndex) => {
    const newWorkCenterCategoryList = cloneDeep(workCenterCategoryList);
    newWorkCenterCategoryList.splice(sourceIndex, 1);
    newWorkCenterCategoryList.splice(destIndex, 0, workCenterCategoryList[sourceIndex]);
    const oldIds = sortBy(
      map(workCenterCategoryList, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const newIds = sortBy(
      map(newWorkCenterCategoryList, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const differences = differenceWith(newIds, oldIds, isEqual);
    setWorkCenterCategoryList(newWorkCenterCategoryList);
    await Promise.all(
      differences.map((difference) => {
        const [id, index] = difference;
        return workCenterCategoryUpdate({
          variables: {
            id,
            input: coerceInput({ position: index + 1 }),
          },
        });
      })
    );
    pageRefetch();
  };

  const onItemDragEnd = async (sourceIndex, destIndex) => {
    const newWorkCenterCategory = cloneDeep(selectedWorkCenterCategory);
    newWorkCenterCategory.workCenterItems.splice(sourceIndex, 1);
    newWorkCenterCategory.workCenterItems.splice(
      destIndex,
      0,
      selectedWorkCenterCategory.workCenterItems[sourceIndex]
    );
    const oldIds = sortBy(
      map(selectedWorkCenterCategory.workCenterItems, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const newIds = sortBy(
      map(newWorkCenterCategory.workCenterItems, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const differences = differenceWith(newIds, oldIds, isEqual);
    setSelectedWorkCenterCategory(newWorkCenterCategory);
    await Promise.all(
      differences.map((difference) => {
        const [id, index] = difference;
        return workCenterItemUpdate({
          variables: {
            id,
            input: coerceInput({ position: index + 1 }),
          },
        });
      })
    );
    pageRefetch();
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    // no drops between lists
    if (source.droppableId !== destination.droppableId) {
      return;
    }
    if (source.droppableId === 'itemCategories') {
      onCategoryDragEnd(source.index, destination.index);
    }
    if (source.droppableId === 'items') {
      onItemDragEnd(source.index, destination.index);
    }
  };

  const handleSetEditModalToggle = (e, submitType) => {
    if (submitType) {
      if (submitType === 'close') {
        setEditModalToggle(!editModalToggle);
      }
    } else {
      setEditModalToggle(!editModalToggle);
    }
    if (editModalToggle) {
      setSelectedWorkCenterItem({});
      setSelectedWorkCenterItemId(null);
    }
  };

  const workCenterItemEditClicked = () => {
    handleSetEditModalToggle();
  };

  const workCenterItemDeleteClicked = async () => {
    const mutationData = {
      variables: {
        workCenterCategoryId: selectedWorkCenterCategory.id,
        id: selectedWorkCenterItem.id,
      },
    };
    try {
      setSelectedWorkCenterItem({});
      setSelectedWorkCenterItemId(null);
      dispatch(
        settingsSet({
          mutating: true,
        })
      );
      await workCenterCategoryWorkCenterItemDelete(mutationData);
      toastSuccess('Work Center Item delete ok');
      pageRefetch();
    } catch (err) {
      console.log(err.toString());
      toastError('Work Center Item delete failed');
    } finally {
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const handleWorkCenterItemSubmit = async (data, submitType) => {
    handleSetEditModalToggle(undefined, submitType);
    const workCenterCategory = workCenterCategoryList.find(
      (wc) => wc.workCenterName === data.workCenterName
    );
    const submitData = cloneDeep(data);
    delete submitData.workCenterName;
    submitData.workCenterCategoryId = workCenterCategory.id;
    if (
      !selectedWorkCenterItem.id ||
      selectedWorkCenterItem.workCenterCategoryId !== submitData.workCenterCategoryId
    ) {
      submitData.position = get(workCenterCategory, ['workCenterItems'], []).length + 1;
    }
    if (submitData.itemPricingUnitPrice === '') {
      submitData.itemPricingUnitPrice = null;
    }
    if (!selectedWorkCenterItem.id) {
      submitData.workCenterItemPricings = [
        pick(submitData, ['itemPricingFixed', 'itemPricingUnitPrice']),
      ];
    } else if (selectedWorkCenterItem.workCenterItemPricings.length === 0) {
      submitData.workCenterItemPricings = [
        {
          workCenterItemId: selectedWorkCenterItem.id,
          ...pick(submitData, ['itemPricingFixed', 'itemPricingUnitPrice']),
        },
      ];
    } else {
      const firstWorkCenterItemPricing = first(sortedWorkCenterItemPricings());
      if (
        firstWorkCenterItemPricing.itemPricingFixed !== submitData.itemPricingFixed ||
        firstWorkCenterItemPricing.itemPricingUnitPrice !==
          submitData.itemPricingUnitPrice
      ) {
        submitData.workCenterItemPricings = [
          {
            workCenterItemId: selectedWorkCenterItem.id,
            ...pick(submitData, ['itemPricingFixed', 'itemPricingUnitPrice']),
          },
          ...map(get(selectedWorkCenterItem, ['workCenterItemPricings'], []), (wcip) =>
            coerceInput(
              pick(wcip, [
                'id',
                'workCenterItemId',
                'itemPricingFixed',
                'itemPricingUnitPrice',
              ])
            )
          ),
        ];
      }
    }
    delete submitData.itemPricingFixed;
    delete submitData.itemPricingUnitPrice;
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { workCenterCategoryId: workCenterCategory.id, input },
    };
    if (selectedWorkCenterItem.id) {
      mutation = workCenterCategoryWorkCenterItemUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = selectedWorkCenterItem.id;
    } else {
      mutation = workCenterCategoryWorkCenterItemCreate;
      mutationMessageAction = 'create';
    }
    try {
      dispatch(
        settingsSet({
          mutating: true,
        })
      );
      await mutation(mutationData);
      toastSuccess(`Work Center Item ${mutationMessageAction} succeeded`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      pageRefetch();
    } catch (err) {
      const { errorMessage, submitErrors } = handleSubmitError(err);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      toastError(errorMessage);
      return submitErrors;
    }
    return undefined;
  };

  const renderContent = () => {
    const {
      enums: { enums },
    } = pageData;
    return (
      <>
        <WorkCenterItemForm
          open={editModalToggle}
          workCenterCategory={selectedWorkCenterCategory}
          workCenterCategoryList={workCenterCategoryList}
          enums={enums}
          handleSetModalToggle={handleSetEditModalToggle}
          handleWorkCenterItemSubmit={handleWorkCenterItemSubmit}
          workCenterItem={selectedWorkCenterItem}
        />
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">Work Centers</h1>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Col sm={3} style={{ height: '73vh', overflow: 'scroll' }}>
              <Droppable key="itemCategories" droppableId="itemCategories">
                {(categoryDroppableProvided) => (
                  <ListGroup
                    ref={categoryDroppableProvided.innerRef}
                    {...categoryDroppableProvided.droppableProps}
                  >
                    {map(workCenterCategoryList, (category, index) => (
                      <Draggable
                        key={`item-category-${category.id}`}
                        draggableId={`item-category-${category.id}`}
                        index={index}
                      >
                        {(categoryDraggableProvided) => (
                          <ListGroup.Item
                            ref={categoryDraggableProvided.innerRef}
                            {...categoryDraggableProvided.draggableProps}
                            {...categoryDraggableProvided.dragHandleProps}
                            action
                            as="div"
                            active={category.id === selectedWorkCenterCategoryId}
                            data-id={category.id}
                            onClick={workCenterCategoryClicked}
                          >
                            {category.workCenterName}
                          </ListGroup.Item>
                        )}
                      </Draggable>
                    ))}
                    {categoryDroppableProvided.placeholder}
                  </ListGroup>
                )}
              </Droppable>
            </Col>
            <Col
              sm={3}
              style={{ height: '73vh', overflow: 'scroll', marginLeft: '-15px' }}
            >
              {!selectedWorkCenterCategory.id && <p>Select a category</p>}
              {selectedWorkCenterCategory.id &&
                selectedWorkCenterCategory.workCenterItems.length === 0 && (
                  <p>No Items</p>
                )}
              {selectedWorkCenterCategory.id &&
                selectedWorkCenterCategory.workCenterItems.length > 0 && (
                  <Droppable key="items" droppableId="items">
                    {(itemDroppableProvided) => (
                      <ListGroup
                        ref={itemDroppableProvided.innerRef}
                        {...itemDroppableProvided.droppableProps}
                      >
                        {map(
                          selectedWorkCenterCategory.workCenterItems,
                          (workCenterItem, index) => (
                            <Draggable
                              key={`item-${workCenterItem.id}`}
                              draggableId={`item-${workCenterItem.id}`}
                              index={index}
                            >
                              {(itemDraggableProvided) => (
                                <ListGroup.Item
                                  ref={itemDraggableProvided.innerRef}
                                  {...itemDraggableProvided.draggableProps}
                                  {...itemDraggableProvided.dragHandleProps}
                                  action
                                  variant="info"
                                  as="div"
                                  active={workCenterItem.id === selectedWorkCenterItemId}
                                  data-id={workCenterItem.id}
                                  onClick={workCenterItemClicked}
                                >
                                  {workCenterItem.itemDescription}
                                </ListGroup.Item>
                              )}
                            </Draggable>
                          )
                        )}
                        {itemDroppableProvided.placeholder}
                      </ListGroup>
                    )}
                  </Droppable>
                )}
              {selectedWorkCenterCategory.id && (
                <Button onClick={handleSetEditModalToggle} disabled={editModalToggle}>
                  Add Work Center Item
                </Button>
              )}
            </Col>
            <Col sm={6}>
              {!selectedWorkCenterItem.id && <p>Select an item</p>}
              {selectedWorkCenterItem.id && (
                <WorkCenterCategoryListWorkCenterItemShow
                  workCenterItem={selectedWorkCenterItem}
                  workCenterItemEditClicked={workCenterItemEditClicked}
                  workCenterItemDeleteClicked={workCenterItemDeleteClicked}
                />
              )}
            </Col>
          </DragDropContext>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default WorkCenterCategoryList;
