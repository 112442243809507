import { useState, useEffect, useMemo } from 'react';
import { Col, Row, ListGroup } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import cloneDeep from 'lodash.clonedeep';
import delay from 'lodash.delay';
import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';
import map from 'lodash.map';
import sortBy from 'lodash.sortby';
import get from 'lodash.get';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import OacItemListOacItemShow from '../components/oac_item_list/oac_item_show';

import { coerceInput } from '../lib/utils';

import oacItemUpdateMutation from '../mutations/oac_item_update_mutation';
import oacCategoryUpdateMutation from '../mutations/oac_category_update_mutation';
import oacCategoryListQuery from '../queries/oac_category_list_query';

const OacItemList = () => {
  const [oacCategoryList, setOacCategoryList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);
  const [selectedItemCategoryId, setSelectedItemCategoryId] = useState(null);

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const [oacItemUpdate] = useMutation(oacItemUpdateMutation);
  const [oacCategoryUpdate] = useMutation(oacCategoryUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(oacCategoryListQuery, { notifyOnNetworkStatusChange: true });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const oacCategories = useMemo(() => {
    if (pageData?.oacCategoryList) {
      return get(pageData, ['oacCategoryList']);
    }
    return [];
  }, [pageData]);

  useEffect(() => {
    if (oacCategories) {
      const sortedOacCategories = map(sortBy(oacCategories, 'position'), (ic) => ({
        ...ic,
        oacItems: sortBy(ic.oacItems, 'position'),
      }));
      setOacCategoryList(sortedOacCategories);
    }
  }, [oacCategories]);

  useEffect(() => {
    if (selectedItemCategoryId) {
      try {
        const oacCategory = oacCategoryList.find(
          (ic) => ic.id === selectedItemCategoryId
        );
        setSelectedItemCategory(oacCategory);
      } catch (err) {
        setSelectedItemCategory(null);
      }
    } else {
      setSelectedItemCategory(null);
    }
  }, [selectedItemCategoryId]);

  useEffect(() => {
    if (selectedItemCategoryId && selectedItemId) {
      try {
        // const oacItem = oacCategoryList
        //   .find((ic) => ic.id === selectedItemCategoryId)
        const oacItem = selectedItemCategory.oacItems.find(
          (i) => i.id === selectedItemId
        );
        setSelectedItem(oacItem);
      } catch (err) {
        setSelectedItem(null);
      }
    } else {
      setSelectedItem(null);
    }
  }, [selectedItemCategoryId, selectedItemId]);

  const oacCategoryClicked = (e) => {
    const itemCategoryId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    setSelectedItemId(null);
    if (selectedItemCategoryId === itemCategoryId) {
      setSelectedItemCategoryId(null);
    } else {
      setSelectedItemCategoryId(itemCategoryId);
      delay(
        (target) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          target.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        50,
        e.currentTarget
      );
    }
  };

  const oacItemClicked = (e) => {
    e.stopPropagation();
    const itemId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    if (selectedItemId === itemId) {
      setSelectedItemId(null);
    } else {
      setSelectedItemId(itemId);
    }
  };

  const onItemCategoryDragEnd = (sourceIndex, destIndex) => {
    const newOacCategoryList = cloneDeep(oacCategoryList);
    newOacCategoryList.splice(sourceIndex, 1);
    newOacCategoryList.splice(destIndex, 0, oacCategoryList[sourceIndex]);
    const oldIds = sortBy(
      map(oacCategoryList, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const newIds = sortBy(
      map(newOacCategoryList, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const differences = differenceWith(newIds, oldIds, isEqual);
    setOacCategoryList(newOacCategoryList);
    differences.map((difference) => {
      const [id, index] = difference;
      return oacCategoryUpdate({
        variables: {
          id,
          input: coerceInput({ position: index + 1 }),
        },
      });
    });
  };

  const onItemDragEnd = (sourceIndex, destIndex) => {
    const newOacCategory = cloneDeep(selectedItemCategory);
    newOacCategory.oacItems.splice(sourceIndex, 1);
    newOacCategory.oacItems.splice(
      destIndex,
      0,
      selectedItemCategory.oacItems[sourceIndex]
    );
    const oldIds = sortBy(
      map(selectedItemCategory.oacItems, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const newIds = sortBy(
      map(newOacCategory.oacItems, (ic, index) => [ic.id, index]),
      (i) => i[0]
    );
    const differences = differenceWith(newIds, oldIds, isEqual);
    setSelectedItemCategory(newOacCategory);
    differences.map((difference) => {
      const [id, index] = difference;
      return oacItemUpdate({
        variables: {
          id,
          input: coerceInput({ position: index + 1 }),
        },
      });
    });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    // no drops between lists
    if (source.droppableId !== destination.droppableId) {
      return;
    }
    if (source.droppableId === 'itemCategories') {
      onItemCategoryDragEnd(source.index, destination.index);
    }
    if (source.droppableId === 'items') {
      onItemDragEnd(source.index, destination.index);
    }
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">OAC Items</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Col sm={3} style={{ height: '73vh', overflow: 'scroll' }}>
            <Droppable key="itemCategories" droppableId="itemCategories">
              {(itemCategoryDroppableProvided) => (
                <ListGroup
                  ref={itemCategoryDroppableProvided.innerRef}
                  {...itemCategoryDroppableProvided.droppableProps}
                >
                  {map(oacCategoryList, (itemCategory, index) => (
                    <Draggable
                      key={`item-category-${itemCategory.id}`}
                      draggableId={`item-category-${itemCategory.id}`}
                      index={index}
                    >
                      {(itemCategoryDraggableProvided) => (
                        <ListGroup.Item
                          ref={itemCategoryDraggableProvided.innerRef}
                          {...itemCategoryDraggableProvided.draggableProps}
                          {...itemCategoryDraggableProvided.dragHandleProps}
                          action
                          as="div"
                          active={itemCategory.id === selectedItemCategoryId}
                          data-id={itemCategory.id}
                          onClick={oacCategoryClicked}
                        >
                          {itemCategory.name}
                        </ListGroup.Item>
                      )}
                    </Draggable>
                  ))}
                  {itemCategoryDroppableProvided.placeholder}
                </ListGroup>
              )}
            </Droppable>
          </Col>
          <Col sm={3} style={{ height: '73vh', overflow: 'scroll', marginLeft: '-15px' }}>
            {selectedItemCategory ? (
              <Droppable key="items" droppableId="items">
                {(itemDroppableProvided) => (
                  <ListGroup
                    ref={itemDroppableProvided.innerRef}
                    {...itemDroppableProvided.droppableProps}
                  >
                    {map(selectedItemCategory.oacItems, (oacItem, index) => (
                      <Draggable
                        key={`item-${oacItem.id}`}
                        draggableId={`item-${oacItem.id}`}
                        index={index}
                      >
                        {(itemDraggableProvided) => (
                          <ListGroup.Item
                            ref={itemDraggableProvided.innerRef}
                            {...itemDraggableProvided.draggableProps}
                            {...itemDraggableProvided.dragHandleProps}
                            action
                            variant="info"
                            as="div"
                            active={oacItem.id === selectedItemId}
                            data-id={oacItem.id}
                            onClick={oacItemClicked}
                          >
                            {oacItem.itemDescription}
                          </ListGroup.Item>
                        )}
                      </Draggable>
                    ))}
                    {itemDroppableProvided.placeholder}
                  </ListGroup>
                )}
              </Droppable>
            ) : (
              <p>Select a pricing category</p>
            )}
          </Col>
          <Col sm={6}>
            {selectedItem ? (
              <OacItemListOacItemShow oacItem={selectedItem} />
            ) : (
              <p>Select a pricing item</p>
            )}
          </Col>
        </DragDropContext>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default OacItemList;
