import userQuery from '../queries/user_query';
import cleanCache from './clean_cache';

const userUpdateUpdateFunction = (cache, { data: { userUpdate } }) => {
  const data = { user: userUpdate };
  cache.writeQuery({ query: userQuery, variables: { id: userUpdate.id.toString() }, data });
  cleanCache(cache);
};

export default userUpdateUpdateFunction;
