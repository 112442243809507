import firstReinspectionFragment from '../fragments/first_reinspection_type_base_fragment';
import cleanCache from './clean_cache';

const firstReinspectionUpdateUpdateFunction = (
  cache,
  { data: { firstReinspectionUpdate } }
) => {
  const data = firstReinspectionUpdate;
  cache.writeFragment({
    fragment: firstReinspectionFragment,
    fragmentName: 'FirstReinspectionTypeBase',
    id: `FirstReinspectionType-${firstReinspectionUpdate.id}`,
    data,
  });
  cleanCache(cache);
};

export default firstReinspectionUpdateUpdateFunction;
