import clone from 'lodash.clone';

import claimInternalNotesFragment from '../fragments/claim_internal_notes_fragment';
import cleanCache from './clean_cache';

const claimInternalNoteCreateUpdateFunction = (
  cache,
  { data: { claimInternalNoteCreate } },
  { context: { recordId } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${claimInternalNoteCreate.internalNoteableId}`,
      fragment: claimInternalNotesFragment,
      fragmentName: 'ClaimInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...claimInternalNoteCreate, err });
    }
    currentData = null;
  }
  if (currentData) {
    // sometimes the optimistic create obect is not cleared away and remains as part of the collection
    // this is a hack to clean that up. to duplicate, go offline, create an image then a builder report update.
    // go online and optimistic entry will still appear along with the new entry.
    // surprisingly it all works if only image updates are performed
    let currentInternalNotes = clone(currentData.internalNotes);
    if (claimInternalNoteCreate.id !== recordId) {
      currentInternalNotes = currentInternalNotes.filter(
        (internalNote) => internalNote.id !== recordId
      );
    }
    const internalNotes = [...currentInternalNotes, claimInternalNoteCreate];
    try {
      currentData = cache.writeFragment({
        id: `ClaimType-${claimInternalNoteCreate.internalNoteableId}`,
        fragment: claimInternalNotesFragment,
        fragmentName: 'ClaimInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', { ...claimInternalNoteCreate, err });
      }
    }
  }
  cleanCache(cache);
};

export default claimInternalNoteCreateUpdateFunction;
