import omit from 'lodash.omit';
import pick from 'lodash.pick';
import reduce from 'lodash.reduce';

import { qccInspectionOptimisticList } from '../white_lists';

// all keys needed to write a fragment.  Optimistic responses that don't define all these keys
// will fail, often silently or breaking queries for reaons that are hard to debug.
// Basically whatever fragment you're using in the writeFragment you need every one of those keys
// Must be a better way to do this
const requiredForType = {
  QccInspectionType: qccInspectionOptimisticList,
};

const optimistic = ({
  mutationName,
  mutationData,
  extraData = {},
  currentData = {},
  omitKeys = [],
}) => {
  const {
    context: { mutationType, recordType, recordId },
    variables: { input },
  } = mutationData;
  const requiredKeys = requiredForType[mutationData.context.recordType] || [];
  const payload = {
    __typename: recordType,
    ...(mutationType === 'CREATE' &&
      reduce(requiredKeys, (r, k) => ({ ...r, [k]: null }), {})),
    ...(mutationType === 'UPDATE' && omit(pick(currentData, requiredKeys), omitKeys)),
    ...(recordId && { id: recordId }),
    ...(input && omit(input, omitKeys)),
    ...extraData,
  };
  return {
    __typename: 'Mutation',
    [mutationName]: payload,
  };
};

export default optimistic;
