import claimItemFragment from '../fragments/claim_item_type_base_fragment';
import cleanCache from './clean_cache';

const claimClaimItemUpdateUpdateFunction = (cache, { data: { claimClaimItemUpdate } }) => {
  cache.writeFragment({
    fragment: claimItemFragment,
    id: `ClaimItemType-${claimClaimItemUpdate.id}`,
    data: claimClaimItemUpdate,
  });
  cleanCache(cache);
};

export default claimClaimItemUpdateUpdateFunction;
