import clone from 'lodash.clone';

import claimClaimItemsFragment from '../fragments/claim_claim_items_fragment';
import cleanCache from './clean_cache';

const claimClaimItemCreateUpdateFunction = (
  cache,
  { data: { claimClaimItemCreate } },
  { context: { recordId } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${claimClaimItemCreate.claimId}`,
      fragment: claimClaimItemsFragment,
      fragmentName: 'ClaimClaimItemsFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...claimClaimItemCreate, err });
    }
    currentData = null;
  }
  if (currentData) {
    // sometimes the optimistic create obect is not cleared away and remains as part of the collection
    // this is a hack to clean that up. to duplicate, go offline, create an image then a builder report update.
    // go online and optimistic entry will still appear along with the new entry.
    // surprisingly it all works if only item creates are performed
    let currentClaimItems = clone(currentData.claimItems);
    if (claimClaimItemCreate.id !== recordId) {
      currentClaimItems = currentClaimItems.filter((item) => item.id !== recordId);
    }
    const claimItems = [...currentClaimItems, claimClaimItemCreate];
    try {
      currentData = cache.writeFragment({
        id: `ClaimType-${claimClaimItemCreate.claimId}`,
        fragment: claimClaimItemsFragment,
        fragmentName: 'ClaimClaimItemsFragment',
        data: { claimItems },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', { ...claimClaimItemCreate, err });
      }
    }
  }
  cleanCache(cache);
};

export default claimClaimItemCreateUpdateFunction;
