import secondReinspectionFragment from '../fragments/second_reinspection_type_base_fragment';
import cleanCache from './clean_cache';

const secondReinspectionUpdateUpdateFunction = (
  cache,
  { data: { secondReinspectionUpdate } }
) => {
  const data = secondReinspectionUpdate;
  cache.writeFragment({
    fragment: secondReinspectionFragment,
    fragmentName: 'SecondReinspectionTypeBase',
    id: `SecondReinspectionType-${secondReinspectionUpdate.id}`,
    data,
  });
  cleanCache(cache);
};

export default secondReinspectionUpdateUpdateFunction;
