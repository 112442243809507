import secondReinspectionInternalNotesFragment from '../fragments/second_reinspection_internal_notes_fragment';
import cleanCache from './clean_cache';

const secondReinspectionInternalNoteDeleteUpdateFunction = (
  cache,
  { data: { secondReinspectionInternalNoteDelete } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `SecondReinspectionType-${secondReinspectionInternalNoteDelete.internalNoteableId}`,
      fragment: secondReinspectionInternalNotesFragment,
      fragmentName: 'SecondReinspectionInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', {
        ...secondReinspectionInternalNoteDelete,
        err,
      });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const internalNotes = currentData.internalNotes.filter(
        (note) => secondReinspectionInternalNoteDelete.id !== note.id
      );
      currentData = cache.writeFragment({
        id: `SecondReinspectionType-${secondReinspectionInternalNoteDelete.internalNoteableId}`,
        fragment: secondReinspectionInternalNotesFragment,
        fragmentName: 'SecondReinspectionInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...secondReinspectionInternalNoteDelete,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};

export default secondReinspectionInternalNoteDeleteUpdateFunction;
