import builderReportFragment from '../fragments/builder_report_type_base_fragment';
import cleanCache from './clean_cache';

const builderReportUpdateUpdateFunction = (
  cache,
  { data: { builderReportUpdate: builderReportUpdateData } }
) => {
  const data = builderReportUpdateData;
  cache.writeFragment({
    fragment: builderReportFragment,
    id: `BuilderReportType-${builderReportUpdateData.id}`,
    data,
  });
  cleanCache(cache);
};

export default builderReportUpdateUpdateFunction;
